.moviesList::-webkit-scrollbar{
    width: 10px;
}

.moviesList::-webkit-scrollbar-thumb{
    background-color: white;
}

.moviesList::-webkit-scrollbar-track{
    width: 10px;
    background-color: white;
    border-left: 4px solid #222629;
    border-right: 4px solid #222629;
}

.mainVod{
    width: 100%;
    height: 100vh;

    position: relative;
}

.containerVod{
    width: 100%;
    height: calc(100vh - 100px);

    margin-top: 100px; 
    position: absolute;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sliderAndSearch{
    width: 85%;
    /* width: 1549px;       fixed width    */    
    display: flex;
    justify-content: space-between;
}

.line{
    width: 85%;
    /* width: 1549px;       fixed width    */    
    margin-top: 50px;
    border-top: 1px solid #FDFDFE;
}

/* ------------------------------------------------------------------------------------------------- */

.moviesList{
    width: 73%;
    /* width: 1458px;       fixed width    */    
    height: 0px;
    margin-top: 300px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.movieItem{
    width: 829px !important;
    height: 457px !important;
    display: flex;
    background-color: darksalmon;

}


.movieItemInfo{
    /* width: 100px; */
    height: auto;
    margin-left: 12px;
    background-color: blueviolet;
    color: #FFFFFF;
}

.title{
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.03em;
    /* margin-bottom: 25px; */
}

.movieItemInfo h4{
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.03em;
}


.loader{
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}