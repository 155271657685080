
.infiniteContent {
    height:100%; 
    overflow: auto;
    padding: 0px 20px;
}


.infiniteContent::-webkit-scrollbar {
    width: 0px;
  }