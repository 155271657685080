.mainDelete {
  width: 100%;
  height: 100vh;
  position: relative;
}

.containerDelete {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.title {
  text-align: center;
  color: white;
  font-size: 40px;  /* test */
  letter-spacing: 2px;
}

.userItem {
  width: 400px !important;
  height: 300px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #87c232;
  color: white;
  font-size: 40px;  /* test */
  border: 2px solid white;
  border-radius: 15px;
}

.userItem svg {
  font-size: 110px !important;
}

.box {
  width: 900px;
  height: 430px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 15px;
  font-size: 40px;  /* test */
  color: #fff;
}

.boxCont {
  width: 700px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.focusable {
  width: 230px !important;
  height: 80px;
  margin: 0 50px;
  /* background-color: #87c232; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 15px;
}
