  .controllsContainer {
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px 50px 50px 50px;
    position: relative;
  }

  button {
    width: 100 !important;
    height: 100 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: aliceblue !important;
  }
  
  .playButtonsContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .bigPlayButton {
    background-color: #fff !important;
    height: 100px;
    width: 100px;
    color: #000 !important;
  }
  
  .channelInfo {
    float: right;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
  }
  
  .options {
    float: left;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
  }


  .prevProgram{
    opacity: 0;
    position: absolute;
    top: -160px;
    left: 0px;
    color: #fff;
    text-shadow: 0px 0px 5px #000;
    border: 1px solid #fff;
    /* border: 1px solid black; */
    border-bottom: none;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.7);
    padding: 10px;
    width: 100%;
  }

  .nextProgram{
    opacity: 0;
    position: absolute;
    top: -160px;
    right: 0px;
    color: #fff;
    text-shadow: 0px 0px 5px #000;
    border: 1px solid #fff;
    /* border: 1px solid black; */
    border-bottom: none;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.7);
    padding: 10px 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .time{
    color: #F7E975;
    text-shadow: 0px 0px 5px #000;
    margin: 0px 20px;
  }

  .programInfo{
    border: 1px solid #fff;
    /* border: 1px solid black; */
    /* border-bottom: none; */
    border-radius: 10px;
    background-color: rgba(0,0,0,0.7);
    padding: 10px;
    display: flex;
    position: absolute;
    align-items: center;
    font-size: 2.5rem;
    top: -81px;
    left: 0px;
    right: 0px;
  }

  .programName{
    color: rgb(247, 233, 117) !important;
    white-space: nowrap !important;
    max-width: 70% !important;
    overflow: hidden !important;
  }
  
  
  .seekBar{
    position: absolute;
    top: -85px;
    left: 0px;
    right: 0px;
    border-radius: 50px;
    height: 8px;
    background-color: black;
    border: 1px solid lightgray
  }

  .seekBarGray{
    background-color: lightgray !important;
  }

  .seek{
    top: 0px;
    height: 8px;
    background-color: #87C232;
    position: absolute;
    transition-duration: unset;
    transition-property: all;
  }

  .liveBorder{
    height: 8px;
    position: relative;
    transition-duration: unset;
    transition-property: all;
    background-color: lightgray !important;
  }

  .liveThumb{
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%) !important;
    width: 4px;
    height: 0px;
    /* border-radius: 50%; */
    background-color: gray !important;
    z-index: 1;
  }

  .seekThumb{
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%) !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: aliceblue !important;
  }

  .seekThumb:focus{
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%) !important;
    box-shadow: 1px 1px 10px 0px #000000 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: aliceblue !important;
  }

  .currentTime {
    color: #fff
  }

  .cursorTime{
    color: #fff;
    position: absolute;
    bottom: 40px;
    /* top: -80px; */
    white-space: nowrap;
    right: 50%;
    transform: translate(50%, 0);
    font-size: 10px !important;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

/* 
  .startTime{
    position: absolute;
    left: 0px;
    top: -60px;
    color: #fff;
    font-size: 30px !important;
  }

.endTime{
  position: absolute;
  right: 0px;
  top: -60px;
  color: #fff;
  font-size: 30px !important;
} */

.helpText{
  color: red;
}

.controllsContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.controllsContainer  * {
  font-size: 50px !important;
}
