.search{
    display: flex;
    align-items: center;
    position: relative;
}

.search input{
    width: 450px;
    height: 60px;
    padding: 0px 70px 0px 10px ;
    display: block;
    background-color: transparent;
    font-style: italic;
    font-weight: 300;
    font-size: 35px;
    color: #FDFDFE;
    border: 3px solid #FDFDFE;
    border-radius: 10px;
}

.search::before{
    content: "";
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    right: 15px;
    background: url("../../../assets/images/Vector.png") no-repeat center ;
    background-size: cover;
}

.search input:focus{
    background-color: transparent !important;
}
.search input:hover{
    background-color: transparent !important;
}

.search input::placeholder{
    font-style: italic;
    font-weight: 300;
    font-size: 35px;
    color: #FDFDFE;
} 