.playerFromEpg{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

  .containerPlayer{
    width: 100%;
    height: 100%;
    overflow: hidden; 
    position: relative;
  }