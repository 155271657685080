.settings{
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.focusable{
    margin: 20px;
}

.actionBtn{
    border: 1px solid lightgrey;
    border-radius: 15px;
    width: 600px;
    height: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-size: 40px;  /* test */
    position: relative;
    padding: 70px;
}




.disabledBtn::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 15px;
    background-color: rgba(211, 211, 211, 0.616);
}