.mainSelect {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerSelect {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.title {
  text-align: center;
  color: white;
  font-size: 40px;  /* test */
  letter-spacing: 2px;
}

.userItem {
  width: 400px !important;
  height: 300px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #87c232;
  color: white;
  font-size: 40px;  /* test */
  border: 2px solid white;
  border-radius: 15px;
}

.userItem svg {
  font-size: 110px !important;
}

.focusable {
  margin: 20px;
}
.actionBtn {
  border: 1px solid lightgrey;
  border-radius: 15px;
  width: 600px;
  height: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 40px;  /* test */
  position: relative;
  padding: 70px;
}
