.mainScr{
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.menusContainer{
    width: 100%;
    padding: 0px 50px;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
}

/* For Chrome, Safari, and Opera */
.menusContainer::-webkit-scrollbar {
    display: none;
}

/* For Internet Explorer, Edge */
.menusContainer {
    -ms-overflow-style: none;
}

/* For Firefox */
.menusContainer {
    scrollbar-width: none;
}

.focusable{
    margin: 20px;
}

.actionBtn{
    border: 1px solid lightgrey;
    border-radius: 15px;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-size: 40px;  /* test */
    position: relative;
}

.actionBtn svg{
    font-size: 110px !important;
}

.disabledBtn::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 15px;
    background-color: rgba(211, 211, 211, 0.616);
}