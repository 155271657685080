body {
  background-color: #000 !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@font-face {
  font-family: roboto;
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

* {
  font-family: roboto;
  box-sizing: border-box;
  /* transition-duration: 0.5s; */
  outline: none;
}

*:focus:not(.nonScale) {
  background-color: #87c23280;
  transform: scale(1.05);

  border: 2px solid #fff;
  border-radius: 15px;
  box-shadow: none;
  /* transition-duration: 0.2s; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-left: 2px solid #222629;
  border-right: 2px solid #222629;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

.video-js .vjs-big-play-button {
  display: none;
}
.video-js .vjs-control-bar {
  display: flex;
}

.snackClass {
  width: 580px;
  height: 100px;
  font-size: 30px !important;
}

.snackClass .MuiSvgIcon-root {
  font-size: 40px !important;
}

iframe {
  z-index: -1;
}

.focusable {
  width: unset;
}

.top-right-corner{
  position: absolute;
  top: 10px;
  right: 10px;
}
