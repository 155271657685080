.epgContainerInner{
    color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 20px;
}

  .epgCurrentValues{
    font-size: 30px;
    margin-left: 10px;
  }

  /* .nowBadge{
    color: #87C232;
  } */

.currentPlaying{
    color: #87C232;
  }
  