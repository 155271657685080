.mainPlayer {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerPlayer {
  width: 100%;
  height: 100vh;

  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
