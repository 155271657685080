.mainOptions {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerOptions {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.focusable {
  margin: 20px 0;
}

.btnDiv {
  border: 1px solid lightgrey;
  border-radius: 15px;
  width: 500px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;  /* test */
  position: relative;
}

.control {
  font-size: 40px;  /* test */
  color: white;
  margin-bottom: 100px;
}
