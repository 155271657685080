.mainSelect {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerSelect {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.channelsContext {
  width: 90%;
  height: 100%;
  display: flex;
}

.channelsContext > div {
  width: 33%;
}

.channelsListContainer {
  width: 550px;
  height: 100%;
  display: flex;
  align-items: center;
}

.selectChannels {
  width: 100%;
  height: 80%;
  padding: 5px;
  overflow-y: auto;
}

.focusableSelect {
  width: 460px !important;
  height: 70px;
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0 20px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #fff;
  font-size: 30px;
  border: 3px solid;
  border-radius: 15px;
}

.infoChannels {
  color: #fff;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.backgroundGreen {
  background-color: #86c2329f;
}

.svgLogo * {
  color: #fff;
  fill: #fff;
  position: absolute;
}

.channelLogo {
  width: 100px !important;
  height: 60px;
  margin: 0px 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/* //// */

.middleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 40px;  /* test */
  letter-spacing: 2px;
  color: #fff;
  margin-top: 10px;
}

.selectAll,
.ok {
  border: 1px solid lightgrey;
  border-radius: 15px;
  width: 400px !important;
  height: 100px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;  /* test */
  position: relative;
}

.ok {
  margin-bottom: 20px;
  background-color: #87c232;
}
