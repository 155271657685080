.mainWarning {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerWarning {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
}

.context {
  width: 900px;
  height: 500px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: #86c23280;

  text-align: center;
  font-size: 40px;
  color: #fff;

  border: 3px solid;
  border-radius: 12px;
}

.context > span {
  font-weight: 600;
}
