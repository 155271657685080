.mainDetail {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerDetail {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img{
  height: 100%;
  width: auto;
}

.constextDetail {
  width: 84%;
  /* width: 1530px;     84%      fixed width */
  height: 80%;
  /* height: 622px;   80%        fixed height  */

  display: flex;
  /* background-color: crimson; */
}

.imageDiv img {
  width: 100%;
}

.flex {
  width: 100%;
  margin-left: 30px;
  display: flex;
  /* justify-content: space-between; */
}

/* -------------------- */

.flex > div {
  flex: 1;
}

.leftSide {
  /* background-color: rgb(96, 141, 238); */
  color: white;
}

.title {
  font-weight: 700;
  font-size: 45px;
}

.stars {
  font-size: 45px !important;
}

.year,
.stars,
.price,
.genre,
.durationSeconds,
.description {
  margin-top: 40px;
}

.year,
.price,
.genre,
.durationSeconds,
.description {
  font-weight: 500;
  font-size: 27px;
}

.descriptionCont{
  font-weight: 500;
  font-size: 27px;
  margin-bottom: 40px;
  display: grid;
  place-content: center;
  align-content: center;;
  height: 100%;
  overflow: auto;
}


.descriptionNest{
  text-align: center;
}
/* // */

.rightSide {
  width: 500px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.divBtn {
  width: 350px;
  height: 70px;
  margin-bottom: 10px;
  /* background-color: darkturquoise; */
}

.divBtn button {
  /* color: black !important; */
  width: 350px;
  height: 70px;
  font-size: 30px;
  border: 3px solid white;
  border-radius: 10px;
  background-color: #87c232;
}

.modal {
  border: 3px solid #87c232 !important;
  padding: 20px !important;
}
