.dialogText {
  font-size: 40px !important;
  color: #fff;
}

.dialogTitle{
  font-size: 50px !important;
  color: #fff;
}

.modalButtonCont {
  width: 50%;
}

.Dialog {
  background-color: transparent !important;
}

.root{
  width: 100vw;
}

.modalButton:focus {
  background: #87c232;
  border: 2px solid #fdfdfe;
}

.modalCancelButton{
  background-color: transparent !important;
}

.modalButton {
  width: 100% !important;
  height: 100px;
  background: #86c2329a;
  border: 2px solid #6a6e71;
  color: #fff;
  border-radius: 15px;
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;  /* test */
  line-height: 50px;
  letter-spacing: 0.03em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 10px;
}

.newClass {
  background-color: black !important;
}
