.dialogText {
  font-size: 24px !important;
  color: #fff;
}

.modalButtonCont {
  width: 200px;
}

.Dialog {
  background-color: transparent !important;
}

.titleCont{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.btnsCont{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.modalButtonClose{
  cursor: pointer;
  outline: none;
  width: 100px !important;
  height: 40px;
  background: #86c2329a;
  border: 2px solid #6a6e71;
  color: #fff;
  border-radius: 8px;
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.03em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.text{
  white-space: nowrap;
}

.modalButton {
  text-decoration: none;
  cursor: pointer;
  outline: none;
  width: 100% !important;
  height: 40px;
  background: #86c2329a;
  border: 2px solid #6a6e71;
  color: #fff;
  border-radius: 8px;
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.03em;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;
  padding: 20px 30%;
  gap: 10px;
}

.newClass {
  background-color: black !important;
}
