.mainParental {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerParental {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.title,
.text {
  text-align: center;
  color: white;
}

.title {
  font-size: 40px;  /* test */
  letter-spacing: 2px;
}

.text {
  font-size: 30px;
  letter-spacing: 2px;
}

.users {
  max-width: 1780px;
  height: 800px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
}

.userItem {
  width: 400px !important;
  height: 300px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 40px;  /* test */
  border: 2px solid white;
  border-radius: 15px;
}

.userItem svg {
  font-size: 110px !important;
}
