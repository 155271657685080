.header{
    height: 100px;
    padding: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;
    font-size: 40px;  /* test */
}

.epgFullContainer{
    background-image: url("../assets/images/background2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #222629;
    height: 100vh;
    position: relative;
}
.playerFromEpg{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerTitle{
    margin-left: 40px;
    color: #fff;
}

.EpgBody{
    height: calc(100% - 150px);
    display: flex;
}

.dayPicker{
    padding: 20px;
    width: 220px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
}

.day{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    width: 150px;
    height: 150px;
    word-wrap: break-word;
    color: #fff;
    font-size: 32px;
    white-space: break-spaces;
}

.selectedDay{
    border: 2px solid #87C232 !important;
    border-radius: 15px;
}

.today{
    color: rgb(253, 242, 143);
}



.programs{
    width: 90%;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    /* scroll-behavior: smooth; */
}

.programCont{
    width: calc(100% - 100px);
    padding: 20px;
}

.program{
    padding: 0px 50px;
    color: #fff;
    font-size: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 40px;
}

.time{
    color: rgb(163, 160, 160);
    font-size: 32px;
    white-space: nowrap;
    margin-right: 10px;
}

.hasArchive{
    position: relative;
}

.hasArchive::after{
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    left: 10px;
    border-radius: 50%;
    background-color: red;
}

.currentProgram{
    color: yellow !important;
}