.mainEdit {
  width: 100%;
  height: 100vh;
  position: relative;
}

.containerEdit {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.title {
  text-align: center;
  color: white;
  font-size: 40px;  /* test */
  letter-spacing: 2px;
}

.helperText{
  text-align: center;
  font-family: 'roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: 0.03em;
  color: #ef5350;
}

.focusable {
  margin: 20px 0;
}

.name,
.pin,
.btn {
  width: 460px;
  height: 90px;
  padding: 0 20px;
  color: white;
  font-size: 30px;
  background-color: transparent;
  border: 3px solid rgb(189, 189, 189);
  border-radius: 15px;
}

.containerEdit button {
  background-color: #87c232;
  font-size: 30px;
  font-weight: 500;
}

.containerEdit input::placeholder {
  color: gray;
  font-size: 30px;
}

.focusableBtn {
  width: 460px;
  height: 90px;
  padding: 0 20px;
  margin-top: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 30px;
  background-color: #87c232;
  border: 3px solid rgb(189, 189, 189);
  border-radius: 15px;
}

.form {
  display: flex;
  flex-direction: column;
}
