.content {
    width: 100vw;
    height: 100vh;
    padding-top: 120px;
    background: transparent;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: flex-end;
}

.clearIconCont {
    height: 100%;
    font-size: 40px;  /* test */
    padding: 0px;
    margin: 0px;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
}

.loaderContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.horizontalScroll {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5px 25px;
}

.HitemContainter {
    margin: 0px 15px;
}

.activeHItem {
    border: 3px solid #87C232 !important;
    color: #87C232 !important;
}


.logoChannel {
    height: 60px;
    width: auto;
}

.categoryItem {
    padding: 15px 30px;
    color: #fff;
    border: 1px solid #fff;
    font-size: 35px;
    white-space: nowrap;
    border-radius: 15px;
}


.playlistItem {
    padding: 15px 30px;
    color: #fff;
    border: 1px solid #fff;
    font-size: 35px;
    border-radius: 15px;
}

/* For Chrome, Safari, and Opera */
.horizontalScroll::-webkit-scrollbar {
    display: none;
}

/* For Internet Explorer, Edge */
.horizontalScroll {
    -ms-overflow-style: none;
}

/* For Firefox */
.horizontalScroll {
    scrollbar-width: none;
}


.channel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    position: relative;
    color: #fff;
    border: 1px solid #fff;
    font-size: 40px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.channelNameWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.368);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 10;
}

.indicatorCont {
    width: 20px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin: 5px 0;
}

.loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 1000;
}

.channelNested {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
}

.actionItem {
    padding: 0;
    margin: 0;
}

.videoContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.fullScreen {
    width: 100vw;
    height: 100vh;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    width: 50%;
    gap: 20px;
}

.channelContainer {
    height: 60vh;
    padding: 10px 50px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.checkboxContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.checkbox {
    width: 40px;
    height: 40px;
    accent-color: #87C232;
}

.elips {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    max-width: 250px;
    overflow: hidden;
}

.input {
    width: 100%;
    border-radius: 10px;
    font-family: 'roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    letter-spacing: 0.03em;
    color: #fff;
    padding: 10px 30px;
    background: #474a4f9a;
    border: 2px solid #6A6E71;
}



.category {
    min-width: 50px;
    text-align: center;
    padding: 0;
    margin: 10px;
}

.categorContainer {
    display: flex;
    border-width: 1px;
    margin: 10px;
    border-radius: 10px;
}

.video {
    height: 30%;
    width: 100%;
    background-color: #000;
}