.moviesList {
  width: 80%;
  height: 520px;
  margin-top: 60px;
  padding: 15px;
  /* background-color: rgba(126, 252, 0, 0.233); */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  overflow-y: scroll;
}

.movieItem {
  width: 400px !important;
  height: 220px;
  display: flex;
  overflow: hidden;
  margin: 25px;
  /* background-color: rgb(122, 152, 233); */
}


.movieItemInfo {
  /* height: auto; */
  height: 213px;
  margin-left: 6px;
  /* background-color: rgb(122, 233, 155); */
  overflow: hidden;
  /* margin-top: 10px; */
  color: #ffffff;
}

/* movieItemInfo */
.img {
  height: 100%;
  width: auto;
}
.title {
  font-weight: 300;
  font-size: 30px;
}

.year {
  font-weight: 300;
  font-size: 20px;
  margin-top: 5px;
}

.stars {
  margin-left: -2px;
  margin-top: 3px;
}

.price {
  margin-top: 3px;
}
