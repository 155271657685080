.background {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.wrapper {
  position: relative;
  border-radius: 20px;
  height: 40%;
  width: fit-content;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.logo {
  margin-right: 150px;
  width: auto;
  height: 40%;
  aspect-ratio: 1.19;
  background-image: url("../../assets/images/iconTransparent.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.title {
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 0.03em;
}

.input {
  width: 28vw;
  border-radius: 15px;
  font-family: 'roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 3.5rem;
  letter-spacing: 0.03em;
  color: #fff;
  padding: 3% 5%;
  background: #474a4f9a;
  border: 2px solid #6A6E71;
}

.input:focus {
  background: #474a4f;
  border: 2px solid #FDFDFE;
}

.submit:focus {
  background: #87C232;
  border: 2px solid #FDFDFE;
}

.submit {
  width: 28vw !important;
  background: #86c2329a;
  border: 2px solid #6A6E71;
  color: #fff;
  border-radius: 15px;
  font-family: 'roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 3.5rem;
  letter-spacing: 0.03em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2%;
  gap: 10px;
}

.helperText {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.03em;
  color: #ef5350;
}


.focusable {
  width: 100%;
}

.container {
  overflow: hidden;
  flex: 1;
  width: 100%;
  padding: 0px 130px;
  height: 100vh;
  box-sizing: border-box;
  background-color: black;
  align-items: center;
  justify-content: center;
  color: aliceblue;
}

.smallContainer {
  max-width: 500px;
}

.controllsContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.channelsList {
  display: block;
  position: absolute;
  top: 0px;
  margin-top: 120px;
  /* background-color: red; */
  left: 10;
  height: calc(100% - 150px);
  padding: 10px 50px;
  width: 300px;

  /* scroll-behavior: smooth; */
  overflow-y: auto;
  /* transition-duration: 10s !important; */
}


.channelsList::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.channelsList::-webkit-scrollbar-track {
  background: #fff;
  width: 10px;
  border-left: 4px solid #000;
  border-right: 4px solid #000;
}

/* Handle */
.channelsList::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}


.channelsList * {
  font-size: 40px;
}

.controllsContainer * {
  font-size: 3.5rem !important;
}


.epgContainer {
  position: absolute;
  right: 0;
}

.containerPlayer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.small {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.video-js.vjs-fluid {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
}

.video-js .vjs-tech {
  width: 100% !important;
  height: 100% !important;
}

.vjs_video_3-dimensions.vjs-fluid {
  padding-top: unset;
}

video {
  object-fit: unset;
}


.rootMui {
  margin-bottom: 20px !important
}

.rootMui:focus {
  transform: none !important;
}

.checkboxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 28vw;
  height: 50px;
}

.checkbox {
  width: 50px;
  height: 50px;
  accent-color: #87C232;
}

.label {
  font-size: 3.5rem;
  white-space: nowrap;
  color: #fff;
}