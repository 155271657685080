.buttons::-webkit-scrollbar-track {
  width: 0px;
  height: 0px;
  background: none;
}

.buttons::-webkit-scrollbar-thumb {
  width: 0px;
  height: 0px;

  background: none;
}

.buttons::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.season {
  width: 550px;
  height: 100px;

  display: flex;
  justify-content: space-between;

  /* background-color: deepskyblue; */
}

.logo,
.logo2 {
  margin-top: 15px;
}

.logo2 {
  transform: rotate(180deg);
}

.buttons {
  max-width: 420px;
  /* background-color: limegreen; */
  padding-top: 5px;
  display: flex;

  overflow-x: auto;
  overflow: overlay;
}

.divBtn {
  width: 220px;
  height: 70px;
  margin: 0 20px;
  border: none !important;
}

.divBtn:focus {
  border: none !important;
}

.divBtn > button {
  min-width: 220px !important;
  height: 70px;

  background-color: transparent;
  border: 3px solid;
  border-radius: 15px;
  color: white;
  font-size: 40px;
  font-weight: 500;
}

.divBtn:nth-child(1) {
  margin-left: 100px;
}
.divBtn:last-of-type {
  margin-right: 100px;
}

/* -------------------------------- */

.episodes {
  width: 550px;
  height: 520px;
  /* background-color: gold; */
  overflow-y: auto;
}

.episodeItem {
  width: 500px !important;
  height: 70px;
  margin: 0 auto;
  margin-bottom: 25px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 30px;
  border: 3px solid;
  border-radius: 15px;
}

.episodeItem:nth-child(1) {
  margin-top: 10px;
}
