.header{
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: aliceblue;
    padding: 0px 15px 0px 30px;
    border-bottom: 1px solid #fff;
    font-size: 40px;  /* test */
    position: fixed;
}

.progressCont{
    width: 200px;
    height: 5px;
    border: 1px solid gray;
}

.progress{
    height: 5px;
    background-color: #87C232;
}
.remaining{
    font-size: 20px;
}