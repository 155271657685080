.mainScr {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerUser {
  width: 100%;
  height: calc(100vh - 200px);

  margin-top: 170px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
}

.usersDiv {
  max-width: 1800px;
  min-width: 500px;
  height: 700px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.focusable {
  margin: 20px;
}

.actionBtn {
  border: 1px solid lightgrey;
  border-radius: 15px;
  width: 400px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 40px;  /* test */
  position: relative;
}

.actionBtn svg {
  font-size: 110px !important;
}

.disabledBtn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 15px;
  background-color: rgba(211, 211, 211, 0.616);
}

.input {
  width: 440px;
  height: 100px;
  border-radius: 15px;
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;  /* test */
  line-height: 50px;
  letter-spacing: 0.03em;
  color: #fff;
  padding: 0px 30px;
  background: #474a4f9a;
  border: 2px solid #6a6e71;
}

.input:focus {
  background: #474a4f;
  border: 2px solid #fdfdfe;
}

.lockIcon{
  position: absolute;
  top: 10px;
  right: 10px;
}

.adminIcon{
  position: absolute;
  top: 10px;
  right: 50px;
}

.lockIcon svg {
  font-size: 40px !important;
}

.adminIcon svg {
  font-size: 40px !important;
}