.liveScreen{
  width: 100%;
  height: 100vh;
  position: relative;
}


.smallView{
  padding-top: 110px !important;
}


.numberBox{
  z-index: 10000;
  position: absolute;
 top: 50px;
 left: 50px;
 border: 1px solid #fff;
 padding: 10px 30px;
 color: #fff;
 border-radius: 20px;
 background-color: rgba(0, 0, 0, 0.781);
 /* display: flex;
 justify-content: center;
 align-items: center;
  */
  font-weight: 600;
  font-size: 30px;
}

.fullScreenView{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: fit-content;
  margin: auto 0px;
  display: flex;
  justify-content: center;
}

.channelsList {
  display: block;
  position: absolute;
  top: 0px;
  margin-top: 110px;
  /* background-color: red; */
  left: 10;
  height: calc(100% - 110px);
  padding: 10px 0px;
  width: 300px;

  /* scroll-behavior: smooth; */
  /* overflow-y: auto; */
  /* transition-duration: 10s !important; */
}

.container {
  overflow: hidden;
  flex: 1;
  width: 100%;
  padding: 0px 40px;
  height: 100vh;
  box-sizing: border-box;
  background-color: black;
  align-items: center;
  justify-content: center;
  color: aliceblue;
}

.smallContainer{
  max-width: 500px;
}

.controllsContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}

.controllsContainer  * {
  font-size: 50px !important;
}


.epgContainer{
  position: absolute;
  right: 0;
}


.inPlayerChannels{
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  height: calc(100% - 70px);
  padding: 10px 50px;
  background-color: rgba(0,0,0,0.7);
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 10px;
}

.inPlayerEPGs{
  background-color: rgba(0,0,0,0.7) !important;
  background-image: none !important;
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  right: 0px !important;
  width: 60% !important;
}



.video-js.vjs-fluid{
  width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
}

.video-js .vjs-tech{
  width: 100% !important;
  height: 100% !important;
}

.vjs_video_3-dimensions.vjs-fluid {
  padding-top: unset;
}

video{
  object-fit: unset;
}


.rootMui{
  margin-bottom: 20px !important

}

.rootMui:focus{
transform: none !important;
}