.slider::-webkit-scrollbar {
  height: 10px;
}

.slider::-webkit-scrollbar-thumb {
  height: 8px;
  background-color: rgb(255, 255, 255);
}

.slider::-webkit-scrollbar-track {
  height: 8px;
  background-color: white;
  border-top: 4px solid #222629;
  border-bottom: 4px solid #222629;
}

.slider {
  max-width: 900px;
  height: 120px;
  padding: 5px;
  display: flex !important;
  overflow-x: auto;
}

.sliderItem {
  /* width: 300px !important; */
  height: 60px;
  padding: 5px 0;
  border-radius: 10px;
  border: 3px solid #fdfdfe;
  display: block;
  font-weight: 400;
  font-size: 35px;
  color: #fdfdfe;
  white-space: nowrap;
}

.width {
  width: 250px !important;
  text-align: center;
}

.sliderItem:nth-child(n + 1) {
  margin-right: 60px;
}

.sliderItem:first-child {
  margin-left: 3px;
}
.sliderItem:last-child {
  margin: 0;
  margin-right: 3px;
}
