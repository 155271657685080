.mainAge {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerAge {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ageContext {
  width: 90%;
  height: 90%;
  display: flex;
}

.ageContext > div {
  width: 33%;
}

.ageListContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.focusableAge {
  width: 460px !important;
  height: 70px;
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0 20px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 30px;
  border: 3px solid;
  border-radius: 15px;
}

.itemPlus {
  position: relative;
}

.logoDiv {
  position: absolute;
  right: 10px;
}

.svgLogo * {
  color: #fff;
  fill: #fff;
}

.selectedItem {
  background-color: #6a6e717e;
}

.clearAll {
  position: absolute;
  bottom: 0px;
}

/* // */

.middleAge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 40px;  /* test */
  letter-spacing: 2px;
  color: #fff;
  margin-top: 10px;
  text-align: center;
}

.text {
  text-align: center;
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: 45%;
  right: 25%;
}

.clearAll,
.ok {
  border: 1px solid lightgrey;
  border-radius: 15px;
  width: 400px !important;
  height: 100px;
  color: #fff;
  font-size: 40px;  /* test */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ok {
  background-color: #87c232;
}
