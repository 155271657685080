.mainLang {
  width: 100%;
  height: 100vh;

  position: relative;
}

.containerLang {
  width: 100%;
  height: calc(100vh - 100px);

  margin-top: 100px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  position: absolute;
  top: 100px;
  font-size: 40px;  /* test */
  font-weight: 500;
  color: white;
}

.flex {
  display: flex;
}

.langItem {
  position: relative;
  width: 300px !important;
  height: 200px;

  margin: 0 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 40px;
  font-weight: 500;
  color: white;

  border: 2px solid white;
  border-radius: 15px;
}

.item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selected{
  background-color: #87c23280 !important;
}
