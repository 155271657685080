.channel {
  font-size: 30px !important;
  white-space: nowrap;
  text-shadow: 0px 0px 10px #000;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  border: 2px solid #6A6E71;
  border-radius: 15px;
  margin: 16px 0px;
  
  }

  .channelCont{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
  }

  .channelCont:focus{
    border: 2px solid #FFF;
    border-radius: 15px;
    padding: 0;
  }

  .instr{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
  }

  .redCont, .yellowCont{
    display: flex;
    width: 40%;
    align-items: center;
    gap: 20px;
    color: #FFF;
  }

  .red{
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    background-color: red;
    color: #000;
    margin-right: 10px;
  }
  .yellow{
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    background-color: yellow;
    color: #000;
    margin-right: 10px;
  }

  .channelCont * {
    font-size: 30px;
  }


  .channelLogo {
    width: 100px !important; 
    height: 60px; 
    margin:0px 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .icons{
    float: right;
    display: flex;
  }

  .noFavs{
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }

  .nameCont{
    display: flex;
    align-items: center;
  }

  /* .withArchive{
    position: relative;
  }

  .withArchive::after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translate(-50%, -50%);
      right: 0px;
      border-radius: 50%;
      background-color: red;
  }
   */